<template>
  <div>
    <p class="font-bold mt-3 text-lg" style="margin-left: 20px">School Registrations - {{accountName}}</p>
    <div style="padding: 20px;">
      <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
        :highlight-current-row="false" :highlight-selection-row="false" :header-cell-class-name="'custom-head-cell'"
        :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
        <el-table-column prop="project.project_ename" label="Program"></el-table-column>
        <el-table-column label="Status" width="120px">
          <template slot-scope="scope">
            {{ scope.row.project.project_status == 'active' ? 'Ongoing' : 'Completed' }}
          </template>
        </el-table-column>
        <el-table-column label="Advisor" width="150px">
          <template slot-scope="scope">
            <div v-for="(contact, index) in scope.row.contacts" :key="index">
              <p>{{ formatContactName(contact) }} </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="project.project_ename" label="Operation" width="280">
          <template slot-scope="scope">
            <div class="operations-container">
              <!-- Admin Operations -->
              <div class="admin-operations">
                <el-button 
                  type="danger" 
                  size="small"
                  v-if="user_type != 'advisor'" 
                  @click="showDialog(scope.row)">
                  Assign Advisor
                </el-button>
                
                <el-button 
                  v-if="scope.row.project.exam_time_arr && scope.row.project.exam_time_arr.length > 0"
                  type="primary"
                  size="small"
                  @click="viewExams(scope.row)">
                  View Exams
                </el-button>
                
                <el-button 
                  type="danger"
                  size="small" 
                  v-if="scope.row.project.project_status == 'inactive'"
                  @click="gotoViewStudentResults(scope.row)">
                  View Results
                </el-button>
                
                <el-button 
                  type="danger"
                  size="small" 
                  v-if="scope.row.project.project_status == 'active'"
                  @click="goStudentInfo(scope.row)">
                  Manage Students
                </el-button>
                
                <el-button 
                  v-if="scope.row.project.project_status == 'active'" 
                  type="danger"
                  size="small"
                  @click="showCopyLink(scope.row)">
                  Registration Link
                </el-button>
              </div>

              <!-- Case Operations -->
              <div class="case-operations" v-if="scope.row.case_array && scope.row.case_array.length > 0">
                <div v-for="(case_item, index) in scope.row.case_array" 
                     :key="index"
                     class="case-operation-row">
                  <div class="case-buttons">
                    <el-button 
                      v-if="case_item.case_button_status === 1"
                      type="primary"
                      size="mini"
                      @click="downloadCase(case_item.case_material_url)">
                      {{ case_item.case_title || 'Download Case' }}
                    </el-button>
                    <el-button 
                      v-if="case_item.case_button_status === 0"
                      type="info"
                      size="mini"
                      disabled>
                      Case Not Released
                    </el-button>
                    <el-button 
                      v-if="case_item.submit_button_status === 1"
                      type="success"
                      size="mini"
                      @click="submitCase(scope.row, index)">
                      Submit
                    </el-button>
                    <el-button 
                      v-if="case_item.submit_button_status === 0"
                      type="info"
                      size="mini"
                      disabled>
                      Submission Closed
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="isShowDialog" title="Assign Advisor" width="600px" @close="closeDialog">
      <div>
        <el-form :inline="true" ref="form">
          <el-form-item label="Select a program advisors">
            <el-select v-model="selectContactIds" placeholder="Please select" :multiple="true" style="width: 400px;">
              <el-option v-for="(contact, index) in teacherOpt" :key="index" :label="formatContactName(contact)"
                :value="contact._id.$id">
              </el-option>
            </el-select>
          </el-form-item>
          <p>*Emails to students for the project will also go to the assigned program advisor.</p>
        </el-form>
        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button size="medium" @click="isShowDialog = false">Cancel</el-button>
          <el-button type="primary" size="medium" @click="saveInfo()">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isShowCopyDialog" title="Student Registration Link" width="50%">
      <div class="content">
        <div style="padding: 0 10px">Please copy and share the link with your student for registration</div>
        <div class="linkbox" style="display: flex;width: 100%">
          <span class="link">{{ linkStr }}</span>
          <el-button type="primary" size="medium" @click="copyLink">Copy</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showViewExamsDialog" width="70%">
      <div style="margin-top: 0px;width: 100%;padding: 0px 20px;">
        <p style="text-align: center;color: #ff6450;font-size: 28px;font-weight: bold;">View Exams</p>
        <p style="font-size:16px;text-align: center;margin-top: 20px;">
          You can enter the exam room between the exam open time and exam close time.<br>
          The timer will start clocking down from the time you enter the exam room.
        </p>
      </div>
      <div style="margin-top: 0px;width: 100%;padding: 0px 20px;" 
           v-if="viewProject.account_application?.custom_exam_time != '是' && viewProject.student_supplementary_exam_time != '是'">
        <h2 style="text-align: center;margin-top: 20px;">{{viewProject.project_ename}}</h2>
        <p style="font-size:16px;text-align: center;margin-top: 20px;">
          The standard exam dates and timing refer to the local time of each continent.<br>
          (Asia & Oceania: GMT+8, Europe & Africa: GMT+1, North & SouthAmerica: GMT-4)
        </p>
        <el-table :data="viewProject.exam_time_arr" style="width: 100%; margin-top: 20px;">
          <el-table-column prop="name" label="Exam Name" align="center"></el-table-column>
          <el-table-column prop="exam_begin_time_str" label="Standard Exam Open Time" align="center"></el-table-column>
          <el-table-column prop="exam_end_time_str" label="Standard Exam Close Time" align="center"></el-table-column>
        </el-table>
      </div>
      <div v-if="viewProject.account_application?.custom_exam_time == '是' ">
        <h2 style="text-align: center;margin-top: 20px;">Custom Exam Time of Your School</h2>
        <div style="font-size:16px;text-align: center;margin-top: 20px;">
          <p>If your school has applied for a custom exam time per our policies,<br>please refer to your school's custom schedule.</p>
        </div>
        <el-table :data="Object.values(viewProject.account_application.event_meeting_room_array)" style="width: 100%; margin-top: 20px;">
          <el-table-column prop="event_name" label="Exam Name" align="center">
            <template slot-scope="scope">
              {{scope.row[0]?.name}}
            </template>
          </el-table-column>
          <el-table-column prop="exam_begin_time_str" label="Exam Open Time (UTC Time)" align="center">
            <template slot-scope="scope">
              {{scope.row[0]?.event_start_time ? convertTimeToEng(scope.row[0].event_start_time) : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="exam_end_time_str" label="Exam Close Time (UTC Time)" align="center">
            <template slot-scope="scope">
              {{scope.row[0]?.event_end_time ? convertTimeToEng(scope.row[0].event_end_time) : ''}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px; text-align: center;">
        <el-button type="primary" size="medium" @click="openExamSystem()">
          Go to the Remote Exam System
        </el-button>
        <p style="margin-top: 10px;">The login credentials will be the same as those for this EAE system.</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  updateAccountApplicationContacts,
  getContactsByAccount,
  getSchoolRegistrations,
  postRequest
} from '../../api/eae'
import {
  getUserId,
  getUserType,
  getAccountId,
  setTargetType,
  getAccountName
} from '../../utils/store'
export default ({
  name: 'index',
  data() {
    return {
      user_type: getUserType(),
      dataList: [],
      isShowDialog: false,
      teacherOpt: [],
      rowData: {},
      isShowCopyDialog: false,
      linkStr: '',
      selectContactIds: [],
      showViewExamsDialog: false,
      viewProject: {},
      accountName: getAccountName()
    }
  },
  mounted() {
    this.fetchData()
    this.getSubjectList()
    setTargetType('student')
  },
  methods: {

    gotoViewStudentResults(row) {
      this.$router.push({
        path: '/home/studentResults',
        query: {
          project:row.project.project_ename,
          project_id: row.project._id.$id,
          account_id: getAccountId()
        }
      })
    },

    formatContactName(contact) {
      const capitalizeWords = str => str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

      if (contact.contact_enName) {
        return capitalizeWords(contact.contact_enName);
      }
      
      const lastName = capitalizeWords(contact.contact_lastName);
      const givenName = capitalizeWords(contact.contact_givenName);
      return `${lastName} ${givenName}`;
    },

    getSubjectList() {
      this.teacherOpt = []
      getContactsByAccount(getAccountId()).then((res) => {
        if (res.data.length > 0) {
          this.teacherOpt = res.data
        }
      })
    },
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getSchoolRegistrations(getAccountId(), 'student').then((res) => {
        this.dataList = res.data.map(item => {
          item.case_array = this.processApplicationCases(item);
          return item;
        });
        loading.close()
      })
    },
    tableCellstyle() {
      return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
    },

    convertTimeToEng(time) {
        var date = new Date(time * 1000);
        date = new Date(date.getTime()); // Convert to UTC
        let year = date.getUTCFullYear();
        let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        let day = date.getUTCDate().toString().padStart(2, '0');
        let hours = date.getUTCHours().toString().padStart(2, '0');
        let minutes = date.getUTCMinutes().toString().padStart(2, '0');
        let seconds = date.getUTCSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },
    showDialog(data) {
      this.rowData = data
      this.selectContactIds = []
      this.isShowDialog = true
    },

    closeDialog() {
    },
    saveInfo() {
      if (this.selectContactIds.length <= 0) {
        this.$message.warning('Please select advisors')
        return
      }
      let oldContacts = [];
      // if (this.rowData.application_contacts && this.rowData.application_contacts.length > 0) {
      //   oldContacts = this.rowData.application_contacts
      // }
      let newContacts = this.selectContactIds.concat(oldContacts)
      //合并之后，
      const uniqueContacts = [...new Set(newContacts)]
      updateAccountApplicationContacts(this.rowData._id.$id, uniqueContacts).then((res) => {
        this.$message.success('Assign Advisor Successful')
        this.isShowDialog = false
        this.fetchData()
      })
    },
    goStudentInfo(data) {
      this.$router.push({
        path: '/home/manageStudentList',
        query: {
          project_id: data.project_id,
          project_code: data.project.project_code,
          project_name: data.project.project_ename ? data.project.project_ename : data.project.project_name
        }
      })
    },
    showCopyLink(data) {
      let user_type = getUserType()
      if (user_type == 'advisor') {
        let contact_id = getUserId()
        this.linkStr = 'https://my.eae.international/#/form/registration?account_id=' + data.account_id + '&contact_id=' + contact_id + '&project_code=' + data.project.project_code
      } else {
        this.linkStr = 'https://my.eae.international/#/form/registration?account_id=' + data.account_id + '&project_code=' + data.project.project_code
      }
      this.isShowCopyDialog = true
    },
    copyLink() {
      let that = this
      navigator.clipboard.writeText(this.linkStr)
        .then(function () {
          that.isShowCopyDialog = false
          that.$message.success('Copy Success');
        })
        .catch(function (err) {
          console.error('Failed to copy text: ', err);
        });
    },
    processApplicationCases(application) {
      let case_array = [];
      if (application.project && application.project.case_time_arr) {
        for (let i = 0; i < application.project.case_time_arr.length; i++) {
          let case_button_status = -1;
          let submit_button_status = -1;
          let case_title = '';
          let case_material_url = '';

          let item = application.project.case_time_arr[i];
          let case_release_time = item.case_release_time;
          let case_submission_ddl = item.case_submission_ddl;
          case_material_url = item.case_material_url;
          
          let current_time = parseInt(new Date().getTime() / 1000);
          if (current_time > case_release_time) {
            case_title = item.case_title;
            case_button_status = 1;
          } else {
            case_button_status = 0;
          }
          
          if (case_submission_ddl && case_submission_ddl != false) {
            if (current_time > case_submission_ddl) {
              submit_button_status = 0;
            } else {
              submit_button_status = 1;
            }
          }
          
          case_array.push({
            case_button_status,
            submit_button_status,
            case_title,
            case_material_url
          });
        }
      }
      return case_array;
    },
    downloadCase(url) {
      if (url) {
        window.open(url, '_blank');
      } else {
        this.$message.warning('Case material not available');
      }
    },
    submitCase(row, caseIndex) {
      // Implement your case submission logic here
      console.log('Submit case for:', row.project.project_ename, 'Case index:', caseIndex);
      this.$message.info('Case submission feature to be implemented');
    },
    viewExams(row) {
      this.viewProject = row.project


      postRequest({
        function: 'getAccountApplicationByContact',
        contact_id: getUserId(),
        project_code: row.project.project_code
      }).then(res => {
        if (res.data.data) {
          this.$set(this.viewProject, 'account_application', res.data.data)
        }
      }).catch(err => {
        console.error('Error getting account application:', err)
      })

      this.showViewExamsDialog = true
    },
    openExamSystem() {
      if(this.viewProject.project_BU == 'WAO'){
        window.open('https://my.academic-olympics.world', '_blank')
      } else {
        window.open('https://exam.learningfirst.tech', '_blank')
      }
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
  background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
  font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
  background: var(--c-primary);
  color: white;
  text-align: center;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: Ubuntu-Bold;
}

.linkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  gap: 10px;

  .link {
    flex: auto;
    display: inline-block;
    padding: 0 10px;
    line-height: 36px;
    background: #f2f2f2;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.operations-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.admin-operations {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 4px;

    .el-button {
        margin: 0;  /* Override element-ui default margins */
    }
}

.case-operations {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 1px solid #ebeef5;
    padding-top: 8px;
}

.case-operation-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.case-buttons {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .el-button {
        margin: 0;  /* Override element-ui default margins */
    }
}

/deep/ .el-button--mini {
    padding: 7px 12px;
    font-size: 12px;
}

/* Ensure consistent button heights */
/deep/ .el-button {
    line-height: 1.2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* Make sure table cells can accommodate the content */
/deep/ .el-table__row td {
    padding: 8px;
}

/* Improve text wrapping in */
</style>